































import {Component, Prop, Vue} from "vue-property-decorator";


@Component({
    methods: {},
    components: {}
})

export default class EnstruForm  extends Vue {

    @Prop({
        required: true,
        default: 2022
    })
    year!: number;

    update_date = new Date().toISOString().slice(0, 10);
    update_code = ""

    makeToast(variant: string, title: string, toastbody: string) {
        this.$bvToast.toast(toastbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    } //  сообщение
    // https://nsi.csi.kz/api-py/load-enstru-by-code/192029.560.000007
    //     https://nsi.csi.kz/api-py/load-enstru-by-date/2024/3/22
    private async delete_cost_data() {
        try {
            const response = await fetch('/api-py/delete-cost-data/');
            let result: any;
            if (response.status === 200) {
                result = await response.json();
                this.makeToast("success", "Результат сохранения", result.message);
                if (result.status == true) {
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса load_correct_fields', error.toString());
            return;
        }
    }


    async update_by_code() {
        try {
            const response = await fetch('https://nsi.csi.kz/api-py/load-enstru-by-code/'+this.update_code);
            let result: any;
            if (response.status == 200) {
                result = await response.json();
                this.makeToast("success", "Результат сохранения", "Код обновился");
                if (result.status == true) {
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса update_by_code', error.toString());
            return;
        }
    }

    get update_date_parsed(): string {
        return this.update_date.replaceAll("-", "/")
    }


    async update_by_date() {
        try {
            let result: any;
            const response = await fetch('https://nsi.csi.kz/api-py/load-enstru-by-date/'+this.update_date_parsed);
            if (response.status === 200) {
                result = await response.json();
                this.makeToast("success", "Результат сохранения", "Данные справочника за эту дату обновились");
                if (result.status == true) {
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса update_by_date', error.toString());
            return;
        }
    }


    async created() {

    }


}
