import { Vue, Component } from 'vue-property-decorator';
import draggable from 'vuedraggable';

import store from '@/services/store';
import {accessor, bridge} from '@/modules/widget-yard/store';
import { TWidgetLoadState } from '@/modules/widget-yard/store/types';

// import CPanel from './c-panel';
import VWidget from '@/modules/widget-yard/c-widget/v-widget';
import VueElementLoading from 'vue-element-loading';
import {Ax} from "@/utils";
import { exportingLinear } from './export-widgets-scripts/chart-linear-marker';
import { exportingMultipleAxes } from './export-widgets-scripts/chart-multiple-axes';
import { exportingStackColumn } from './export-widgets-scripts/chart-stack-column';
import { LoadingDataSepApi } from '../report-for-sep/api/LoadingDataSepApi';
import { isEmpty } from 'lodash';
import PowerBiIframe from "@/components/c-power-bi-iframe/Iframe.vue";


interface ISection {
    id: number;
    name: string;
    widgets: any;
}

@Component({
    components: {
        'v-widget': VWidget,
        'draggable': draggable,
        'loading': VueElementLoading,
        'PowerBiIframe': PowerBiIframe
    }
})
export default class SocEco extends Vue {
    public modalIndicatorVisible: boolean = false;
    public allIndicatorArr: any = [];
    public sectionData: any = {};
    public sectionChild: any = {};
    public curSection: any = {};
    public selectedId = 0;
    private fullSizeActive = false;
    private selectedChild: any = {};
    private selectedPostChild: any = {};

    private code = store.state._instanceCode;

    private exportWidgets: any[] = [];

    private allWidgets: any = [];

    private filteredPeriod = 'год';

    private periodicityFilter: Set<string> = new Set();
    private periodicityFilterArray: any[] = [];

    private subSubSectionOptions: any[] = [];

    private clearChildren() {
        this.sectionChild = {};
        this.selectedChild = {};
        this.selectedPostChild = {};
    }

    private clearChild() {
        this.sectionChild = {};
    }

    public async created() {
        this.$root.$on('fullSizeActive', () => {
            this.fullSizeActive = !this.fullSizeActive;
        });
        this.periodicityFilterArray = [];
        await this.getCategory();
        // this.getWidgets();
        // console.log(this.menuSocEcos);

        this.refreshPage();

        let isRenderSelectPostChild = false;
        this.periodicityFilterArray = [...this.periodicityFilter];


        // eslint-disable-next-line @typescript-eslint/no-this-alias
        this.$watch('selectedChild', () => {
            this.subSubSectionOptions = [];
            if (this.selectedChild !== null && Object.keys(this.selectedChild).length > 0) {
                this.sectionData.children.forEach((el: any) => {
                    if (el.parent === this.selectedChild.id) {
                        this.subSubSectionOptions.push(el);
                    }
                });
                this.selectedChild.widgets.sort((a: any, b: any) => a.dictCategoryEntity.index - b.dictCategoryEntity.index);
                const children: any[] = [];
                if (this.selectedChild.children.length > 0) {
                    this.selectedChild.children.forEach((child: any) => {
                        if (child.widgets.length > 0) {
                            children.push(child);
                        }
                    });
                }
                if (children.length > 0) {
                    this.sectionChild = {
                        name: this.selectedChild.name_ru,
                        id: this.selectedChild.id,
                        widgets: this.selectedChild.widgets,
                        children: children
                    };
                    this.getPeriodicityFilter(this.sectionChild);
                    this.filteredPeriod = Array.from(this.periodicityFilter)[0];
                    this.selectedPostChild = {};
                } else {
                    this.sectionChild = {
                        name: this.selectedChild.name_ru,
                        id: this.selectedChild.id,
                        widgets: this.selectedChild.widgets
                    };
                    this.getPeriodicityFilter(this.sectionChild);
                    this.filteredPeriod = Array.from(this.periodicityFilter)[0];
                }
            } else {
                for (const s of this.menuSocEcos) {
                    if (s.id === Number(this.$route.query.id)) {
                        isRenderSelectPostChild = false;
                        this.getPeriodicityFilter(s);
                        this.filteredPeriod = Array.from(this.periodicityFilter)[0];
                    }
                }
                this.clearChild();
            }
        });
        this.$watch('selectedPostChild', () => {
            if (Object.keys(this.selectedPostChild).length > 0) {
                this.sectionChild = {
                    name: this.selectedPostChild.name_ru,
                    id: this.selectedPostChild.id,
                    widgets: this.selectedPostChild.widgets,
                    children: []
                };
                if (this.sectionChild.widgets.length > 0) {
                    isRenderSelectPostChild = true;
                    this.getPeriodicityFilter(this.sectionChild);
                    this.filteredPeriod = Array.from(this.periodicityFilter)[0];

                    this.sectionChild.widgets.sort((a: any, b: any) => a.dictCategoryEntity.index - b.dictCategoryEntity.index);
                }
            } else {
                this.sectionChild = this.selectedChild;
                if (isRenderSelectPostChild) {
                    // console.log("Else select postchild");
                    this.getPeriodicityFilter(this.sectionChild);
                    this.filteredPeriod = Array.from(this.periodicityFilter)[0];
                }
            }
        });
        this.$watch('selectedId', this.clearChildren);

        this.$watch('$route', () => {
            this.periodicityFilterArray = [];

            this.clearChild();
            this.clearChildren();
            this.refreshPage();

            this.periodicityFilterArray = [...this.periodicityFilter];
        });
    }

    private refreshPage() {
        this.curSection.id = Number(this.$route.query.id);
        for (const s of this.menuSocEcos) {
            if (s.id === this.curSection.id) {
                // console.log("this is a route")
                this.sectionData = s;
                this.getPeriodicityFilter(s);
                this.filteredPeriod = Array.from(this.periodicityFilter)[0];
                this.selectedId = this.curSection.id;
            }
        }
    }

    private infoCostForm(info_id: any) {
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                let file = '';
                if (info_id == 14) {
                    file = 'РП СЭП';
                }
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            }
        );
    }

    public onSelectSection(data: any) {
        this.curSection = data;
    }

    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }

    private menuSocEcos: any[] = [];

    private async getCategory() {
        let lists: any[] = [];
        const category: any[] = [];
        await fetch('/api/dict/integration_category')
            .then(response => response.json())
            .then(json => (lists = json));
        lists.sort((a: any, b: any) => a.code.split('.').length - b.code.split('.').length);
        for (const list of lists) {
            list.code = list.code.replace(' \t', '');
            if (list.code.split('.')[0] === '01') {
                list.children = [];
                if (list.parent !== null) {
                    lists.forEach((categ: any) => {
                        if (categ.children !== undefined) {
                            if (categ.id === list.parent) {
                                categ.children.push(list);
                            }
                        }
                    });
                }
            }
            if (list.id === 210) {
                category.push(list);
            }
        }
        const widgets = await this.getWidgets();
        for (const list of lists) {
            list.code = list.code.replace(' \t', '');
            if (list.code.split('.')[0] === '01') {
                list.widgets = [];
                // eslint-disable-next-line no-await-in-loop
                for (const widget of await widgets) {
                    if ((widget.dictCategoryEntity !== undefined) && (widget.dictCategoryEntity !== null)) {
                        if (widget.dictCategoryEntity.index === undefined) {
                            widget.dictCategoryEntity.index = widget.id;
                        }
                        if (list.id === widget.dictCategoryEntity.id) {
                            list.widgets.push(widget);
                        }
                    }
                }
            }
        }
        category[0].children.forEach((list: any) => {
            if (list.children.length > 0) {
                for (const child of list.children) {
                    if (child.widgets.length > 0) {
                        child.widgets.forEach((widget: any) => {
                            list.widgets.push(widget);
                        });
                    }
                    if (child.children.length > 0) {
                        for (const childish of child.children) {
                            if (childish.widgets.length > 0) {
                                childish.widgets.forEach((widget: any) => {
                                    child.widgets.push(widget);
                                });
                            }
                        }
                    }
                }
            }
            const children: any[] = [];
            const postChildren: any[] = [];
            if (list.children.length > 0) {
                list.children.forEach((child: any) => {
                    if (child.widgets.length > 0) {
                        children.push(child);
                        if (child.children.length > 0) {
                            child.children.forEach((childish: any) => {
                                if (childish.widgets.length > 0) {
                                    postChildren.push(childish);
                                }
                            });
                        }
                    }
                });
            }
            if (list.widgets.length > 0) {
                list.widgets.sort((a: any, b: any) => a.dictCategoryEntity.index - b.dictCategoryEntity.index);
                if (children.length > 0) {
                    if (postChildren.length > 0) {
                        this.menuSocEcos.push({
                            name: list.name_ru,
                            id: list.id,
                            code: list.code,
                            widgets: list.widgets,
                            children: children,
                            postChildren: postChildren
                        });
                    } else {
                        this.menuSocEcos.push({
                            name: list.name_ru,
                            id: list.id,
                            code: list.code,
                            widgets: list.widgets,
                            children: children
                        });
                    }
                } else {
                    this.menuSocEcos.push({
                        name: list.name_ru,
                        id: list.id,
                        code: list.code,
                        widgets: list.widgets
                    });
                }
            }
        });
        this.menuSocEcos.forEach((item: any) => {
            const itemes = {
                'code': item.code,
                'name': item.name,
                'widgets': item.widgets,
                'chart': []
            };
            this.exportWidgets.push(itemes);
        });
        this.exportWidgets.sort((a: any, b: any) => parseInt(a.code.split('.')[1]) - parseInt(b.code.split('.')[1]));
        this.exportWidgets.shift();
    }

    private loading = false;

    private exportDone = false;

    private clickDownload(dev: string) {
        if (dev === 'ser') {
            this.downloadExcel('Показатели_СЭР.xlsx')
        }
        if (dev === 'macro') {
            this.downloadExcel('Макроэкономические_показатели.xlsx')
        }
        if (dev === ' ' || dev === 'dev') {
            this.loading = true;
            this.getExport();
            this.getExportMonth();
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            setTimeout(function () {
                const form = {
                    'source': that.$store.state._instanceCode,
                    'widgets': that.exportWidgets,
                    'dev': dev,
                    'module': 'sep'
                };
                that.sendToReport(form);
                that.exportDone = true;
            }, 500);
        }
    }

    private clickDownloadBtn() {
        this.allIndicatorArr = [];
        LoadingDataSepApi.get(`/api/sep/download-journal/by-indicatorId`)
            .then((res) => {
                if (res.status === 200) {
                    res.data.forEach((item: any) => {
                        if (!isEmpty(item.data)) {
                            const itemInit = {
                                active: item.indicator.active,
                                guId: item.indicator.guId,
                                id: item.indicator.id,
                                name: item.indicator.name,
                                child: item.data,
                            };
                            this.allIndicatorArr.push(itemInit);
                        }
                    });
                    console.log("allIndicatorArr", this.allIndicatorArr);
                    this.modalIndicatorVisible = true;
                }
            })
            .catch((err) => console.log("err", err));
    }


    private downloadFile(item: any) {
        console.log('item', item);
        const url = `/api/file-storage/downloadFile/${item.file.id}`;
        Ax(
          {
            url,
            method: "GET",
            data: null,
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          },
          (data: any) => {
            console.log('data', data);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${item.file.name}`);
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            console.log("err", error);
          }
        );
      }


    private getExport() {
        const test: any[] = ['chart-linear-markers', 'chart-multiple-axes', 'chart-stack-column'];
        if (!this.exportDone) {
            for (const category of this.exportWidgets) {
                // eslint-disable-next-line consistent-return
                category.widgets.forEach((item: any) => {
                    if (item.indicatorConfigs[0].periodicity) {
                        if (item.indicatorConfigs[0].periodicity.split(' ')[0] !== 'месяц') {
                            if (item.type === 'chart-linear-markers') {
                                const data = exportingLinear.result(item);
                                const chart = exportingLinear.setOptions(data);
                                if (chart === null) {
                                    return null;
                                }
                                category.chart.push(chart);
                            }
                            if (item.type === 'chart-multiple-axes') {
                                const data = exportingMultipleAxes.result(item);
                                const chart = exportingMultipleAxes.setOptions(data);
                                if (chart === null) {
                                    return null;
                                }
                                category.chart.push(chart);
                            }
                            if (item.type === 'chart-stack-column') {
                                const data = exportingStackColumn.result(item);
                                const chart = exportingStackColumn.setOptions(data);
                                if (chart === null) {
                                    return null;
                                }
                                category.chart.push(chart);
                            }
                        }
                    }
                });
            }
        }
    }

    private getExportMonth() {
        const test: any[] = ['chart-linear-markers', 'chart-multiple-axes', 'chart-stack-column'];
        if (!this.exportDone) {
            for (const category of this.exportWidgets) {
                // eslint-disable-next-line consistent-return
                category.widgets.forEach((item: any) => {
                    if (item.indicatorConfigs[0].periodicity) {
                        if (item.indicatorConfigs[0].periodicity.split(' ')[0] === 'месяц') {
                            if (item.type === 'chart-linear-markers') {
                                const data = exportingLinear.result(item);
                                const chart = exportingLinear.setOptionsMonth(data);
                                if (chart === null) {
                                    return null;
                                }
                                category.chart.push(chart);
                            }
                            if (item.type === 'chart-multiple-axes') {
                                const data = exportingMultipleAxes.result(item);
                                const chart = exportingMultipleAxes.setOptionsMonth(data);
                                if (chart === null) {
                                    return null;
                                }
                                category.chart.push(chart);
                            }
                            if (item.type === 'chart-stack-column') {
                                const data = exportingStackColumn.result(item);
                                const chart = exportingStackColumn.setOptionsMonth(data);
                                if (chart === null) {
                                    return null;
                                }
                                category.chart.push(chart);
                            }
                        }
                    }
                });
            }
        }
    }

    private sendToReport(config: any) {
        // eslint-disable-next-line no-console
        console.log(config);
        Ax(
            {
                url: '/api-py/widget-export',
                method: 'POST',
                data: config
            },
            (data: any) => {
                // eslint-disable-next-line no-console
                console.log(data);
                this.downloadReport(data);
                this.loading = false;
            },
            (error: any) => {
                // eslint-disable-next-line no-console
                console.log(error);
                this.loading = false;
                // eslint-disable-next-line no-alert
                alert('Пожалуйста, обновите страницу и попробуйте снова');
            }
        );
    }

    private downloadReport(url: any) {
        if (url === 'Something went wrong') {
            return;
        }
        window.open('/api-py/widget-export/' + url, '_blank');
    }

    private downloadExcel(url: any) {
        if (url === 'Something went wrong') {
            return;
        }
        window.open('/api-py/snp-export/' + url, '_blank');
    }


    private get indexes() {
        const arr = [];
        const list: any = this.sectionData;
        if (list.widgets !== undefined) {
            for (let i = 0; i < list.widgets.length; i++) {
                arr.push(i);
            }
        }
        return arr;
    }

    private get widgetsPos() {
        const arr: any[] = [];
        const list: any = this.sectionData;
        if (list.widgets !== undefined) {
            list.widgets.forEach((widget: any) => {
                arr.push(widget);
            });
        }
        return arr;
    }

    private set widgetsPos(val: any) {
        // const list: any = this.sectionData;
        const list: any = this.sectionData;
        val.forEach((item: any, index: number) => {
            item.dictCategoryEntity.index = this.indexes[index];
        });
        list.widgets = val;
        this.sectionData = list;
        this.saveWidgets();
    }

    private get widgetsLoadState(): TWidgetLoadState {
        return accessor.widgetsLoadState;
    }

    private saveWidgets() {
        const list: any = this.sectionData;
        list.widgets.forEach((widget: any) => {
            const index = this.allWidgets.findIndex((i: { id: any}) => i.id === widget.id);
            if (index !== -1) {
                this.allWidgets[index] = widget;
            }
        });
        accessor.widgets = this.allWidgets;
        accessor.saveWidgets();
    }

    private async getWidgets() {
        let lists: any[] = [];
        await fetch('/api/datas/hierarchy')
            .then(response => response.json())
            .then(json => (lists = json));
        this.allWidgets = lists;
        return lists;
    }

    // private filteredPeriod = Array.from(this.periodicityFilter)[0];

    public mounted() {
        bridge.loadInitialPub();
    }
    //     if (Array.from(this.periodicityFilter) !== undefined && Array.from(this.periodicityFilter).length > 0) {
    //         this.filteredPeriod = Array.from(this.periodicityFilter)[0];
    //     }
    // }

    private getPeriodicityFilter(periodicity: object | any) {
        // console.log(periodicity)
        // let i = 0;
        this.periodicityFilter = new Set<string>();
        let samplePeriodicity = ['год', 'квартал', 'месяц', 'неделя', 'ежедневная'];
        const result: string[] = ['год', 'квартал', 'месяц', 'неделя', 'ежедневная'];


        periodicity.widgets.forEach((widget: any) => {
            widget.indicatorConfigs.forEach((indicator: any) => {
                if (indicator.periodicity) {
                    this.periodicityFilter.add(indicator.periodicity.split(' ')[0]);
                }
            });
        });

        samplePeriodicity.forEach((item) => {
            if (!this.periodicityFilter.has(item)) {
                result.splice(samplePeriodicity.indexOf(item), 1, 'none');
            }
        });

        const finalResult: string[] = [];
        result.forEach((el) => {
            if (el !== 'none') {
                finalResult.push(el);
            }
        });
        this.periodicityFilter = new Set<string>(finalResult);
        samplePeriodicity = [];
    }
}
