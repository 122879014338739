import { Vue, Component } from 'vue-property-decorator';

import store from '@/services/store';
import {accessor, bridge} from '@/modules/widget-yard/store';
import { EWidgetType, TWidget } from '@/modules/widget-yard/store/types';
import VueElementLoading from 'vue-element-loading';

import SWidget from '@/modules/widget-yard/c-widget/s-widget';
import ChartToneLine from '@/modules/widget-yard/c-widget/c-content-chart-tone-line';
import DemoBudgetExecution from '@/modules/budget/demo-budget-execution/Main.vue';
import { HomeApi } from './api/Api';



interface ISection {
    id: number;
    name: string;
    icon: string;
    widgets: any;
    src: string;
}
interface IWidgetData {
    checked: boolean | null;
    widget: TWidget;
}

@Component({
    components: {
        's-widget': SWidget,
        'chart-tone-line': ChartToneLine,
        'demo-budget-execution': DemoBudgetExecution,
        'loading': VueElementLoading
    }
})
export default class Home extends Vue {
    public sections: ISection[] = [];
    private code: any = store.state._instanceCode;
    private period = { value: 1 };
    private loading = true;
    private fullSizeActive = false;
    private board = false;
    private resultBP: any = [];
    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }

    private demoVisible = false;

    /** социально-экономические показатели */
    private getActuals() {
        const widgets: TWidget[] = accessor.widgets;
        const actuals: IWidgetData[] = [];
        for (const w of widgets) {
            if (w.type === 'chart-actual') {
                actuals.push({ checked: null, widget: w });
            }
        }
        return actuals;
    }

    private getSEM() {
        const widgets: TWidget[] = accessor.widgets;
        const sem: any[] = [];
        let ids: any[] = [];
        if (this.code === '') {
            ids = [1546, 1796, 1619, 2428, 2432];
        }
        if (this.code === '55') {
            ids = [1546, 1796, 1619, 2428, 2432];
        }
        if (this.code === '11') {
            ids = [1546, 1795, 1619, 1765];
        }
        if (this.code === '23') {
            ids = [1546, 1794, 1795, 1619, 2345];
        }
        if (this.code === '47') {
            ids = [1546, 1796, 1795, 1619, 1639];
        }
        if (this.code === '59' || this.code === '19') {
            ids = [1546, 1619, 1639];
        }
        ids.forEach((idx) => {
            for (const w of widgets) {
                if (idx === w.id) {
                    sem.push({ checked: null, widget: w, subHeader: true });
                    break;
                }
            }
        });
        return sem;
    }

    private getSEP() {
        const widgets: TWidget[] = accessor.widgets;
        console.log(widgets)
        const sep: any[] = [];
        let ids: any[] = [];
        if (this.code === '') {
            ids = [2535, 2543, 1918, 2538, 1272];
        }
        if (this.code === '55') {
            ids = [1918, 1272];
        }
        if (this.code === '11') {
            ids = [2266, 1272];
        }
        if (this.code === '23') {
            ids = [2454, 2455, 2456, 2266, 1272];
        }
        if (this.code === '47') {
            ids = [2462, 1918, 1272];
        }
        if (this.code === '59' || this.code === '19') {
            ids = [1918, 2445];
        }
        ids.forEach((idx) => {
            for (const w of widgets) {
                if (idx === w.id) {
                    sep.push({ checked: null, widget: w, subHeader: true });
                    break;
                }
            }
        });
        return sep;
    }

    private currYear = String(new Date().getFullYear())

    private getStrategicWidget() {
        const result: any[] = [];
        if (this.code !== '23' && this.dataCi && this.dataBp) { result.push({ checked: null, subHeader: true, widget: { id: 11, href: '/intercon-forecast', title: 'Взаимоувязка ЦИ и БП на областном уровне', type: 'chart-ci-bp', data: [this.dataCi, this.dataBp] } }); }
        if (this.dataSnp2) { result.push({ checked: null, subHeader: true, widget: { id: 13, href: '/snp', title: 'Соответствие СНП региональным стандартам', type: 'chart-snp', data: this.dataSnp2 } }); }
        if (this.dataSnp3) { result.push({ checked: null, subHeader: true, widget: { id: 14, href: '/snp', title: 'Соответствие СНП региональным стандартам', type: 'chart-snp', data: this.dataSnp3 } }); }
        if (this.dataSnp4) { result.push({ checked: null, subHeader: true, widget: { id: 15, href: '/snp', title: 'Соответствие опорных и спутниковых СНП региональным стандартам', type: 'chart-snp', data: this.dataSnp4 } }); }
        if (this.dataSnp) { result.push({ checked: null, subHeader: true, widget: { id: 16, href: '/snp', title: 'Сельские населенные пункты', type: 'chart-village', data: this.dataSnp } }); }
        return result;
    }

    private getInvestWidget() {
        const result: any[] = [];
        if (this.code !== '23' && this.code !== '47' && this.dataInvest) { result.push({ checked: null, subHeader: true, widget: { id: 21, href: { report: true }, title: 'Бюджетные инвестиционные проекты на ' + this.currYear, type: 'chart-investing', data: this.dataInvest } }); }
        if (this.dataOutcome) { result.push({ checked: null, subHeader: true, widget: { id: 23, href: { outcome: true }, title: 'Расходы на', type: 'chart-invest-bar', data: this.dataOutcome } }); }
        if (this.dataIncome) { result.push({ checked: null, subHeader: true, widget: { id: 22, href: { income: true }, title: 'Доходы на', type: 'chart-invest-bar', data: this.dataIncome } }); }
        return result;
    }

    private getMedias() {
        const medias: IWidgetData[] = [];
        medias.push({ checked: null, widget: { id: 111, title: 'Хронология публикаций', type: EWidgetType.CHART_TONE_LINE, indicatorKeys: [] } });
        return medias;
    }

    private getDataForChartRadiusWidget() {
        const radiusWidget: any[] = [];
        // if (this.dataForm) { radiusWidget.push({ checked: null, widget: { id: 1111, title: 'Бюджетные заявки на 2022', type: 'chart-radius-pie-for-budget', ...this.dataForm, indicatorKeys: [] } }); }
        if (this.dataBip) { radiusWidget.push({ checked: null, subHeader: true, widget: { id: 1113, title: 'Заявки на финансирования проектов на ' + this.currYear, type: 'chart-invest-request', data: this.dataBip } }); }
        if (this.dataBip) { radiusWidget.push({ checked: null, subHeader: true, widget: { id: 1113, title: 'Бюджетные программы на ' + this.currYear, type: 'chart-invest-programs', data: this.dataBip3 } }); }
        // if (this.code !== 23 && this.dataSummary) { radiusWidget.push({ checked: null, widget: { id: 2222, title: 'Бюджетные заявки на 2022', type: 'chart-radius-pie-for-budget', ...this.dataSummary, indicatorKeys: [] } }); }
        if (this.dataBip2) { radiusWidget.push({ checked: null, subHeader: true, widget: { id: 1114, title: 'Заявки на финансирования проектов на ' + this.currYear, type: 'chart-invest-request-list', data: this.dataBip2 } }); }
        return radiusWidget;
    }

    /** все показатели */
    private getWidgets(): IWidgetData[] {
        const widgets: TWidget[] = accessor.widgets;
        const result: IWidgetData[] = [];
        for (const w of widgets) {
            result.push({ checked: null, widget: w });
        }
        return result;
    }

    private get getSections() {
        if (this.sections.length === 0 && this.getWidgets() !== null && this.getWidgets().length > 0 && this.isFetching) {
            this.sections = [];
            this.sections.push({ id: 1, name: 'Стратегическое планирование', src: '/forecast-target-form', icon: 'binocular-graphic', widgets: this.getStrategicWidget() });
            this.sections.push({ id: 2, name: 'Бюджетное планирование', src: '/budget-request', icon: 'calculator-coins', widgets: this.getDataForChartRadiusWidget() });
            this.sections.push({ id: 3, name: 'Исполнение бюджета', src: '/bip-table', icon: 'calculator-coins', widgets: this.getInvestWidget() });
            this.sections.push({ id: 4, name: 'Социально-экономические показатели', src: 'soc-eco', icon: 'graphic', widgets: this.getSEP() });
            this.sections.push({ id: 5, name: 'Социально-экономическая модель', src: 'soc-mod', icon: 'binocular-graphic', widgets: this.getSEM() });
            this.sections.push({ id: 6, name: 'Медиамониторинг', src: 'mm', icon: 'magnifier-user', widgets: this.getMedias() });
            this.loading = false;
        }

        return this.sections;
    }

    private created() {
        this.$root.$on('fullSizeActive', () => {
            this.fullSizeActive = !this.fullSizeActive;
        });

        this.$root.$on('openBoard', () => {
            const self = this;
            setTimeout(function () {
                self.board = !self.board;
            }, 200);
        });

        this.$router.afterEach(() => {
            this.demoVisible = !(window as any).app.plainMenu;
        });
    }

    public dataCi: object = {};
    public dataBp: object = {};
    public dataSnp: object = {};
    public dataSnp2: object = {};
    public dataSnp3: object = {};
    public dataSnp4: object = {};
    public dataInvest: object = {};
    public dataIncome: object = {};
    public dataOutcome: object = {};
    public dataBip: object = {};
    public dataBip2: object = {};
    public dataBip3: object = {};
    public dataForm: object = {};
    public dataSummary: object = {};
    public isFetching = false;
    public isLoadCi = false;
    public isLoadBp = false;
    public isLoadSnp = false;
    public isLoadSnp2 = false;
    public isLoadSnp3 = false;
    public isLoadSnp4 = false;
    public isLoadInvest = false;
    public isLoadIncome = false;
    public isLoadOutcome = false;
    public isLoadBip = false;
    public isLoadBip2 = false;
    public isLoadBip3 = false;
    // public isLoadGr = false;
    public isLoadKat = false;

    private async getCi() {
        if (this.code !== '23') {
            this.dataCi = await HomeApi.getCi()
                .then(res => {
                    this.isLoadCi = true;
                    return res.data;
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        } else {
            this.isLoadCi = true;
        }
    }

    private async getSnp() {
        this.dataSnp = await HomeApi.getSnp()
            .then(res => {
                this.isLoadSnp = true;
                return res.data;
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    private async getSnp2() {
        this.dataSnp2 = await HomeApi.getSnp2()
            .then(res => {
                this.isLoadSnp2 = true;
                return res.data;
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    private async getSnp3() {
        this.dataSnp3 = await HomeApi.getSnp3()
            .then(res => {
                this.isLoadSnp3 = true;
                return res.data;
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    private async getSnp4() {
        this.dataSnp4 = await HomeApi.getSnp4()
            .then(res => {
                this.isLoadSnp4 = true;
                return res.data;
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    private async getInvest() {
        if (this.code !== '23' && this.code !== '47') {
            this.dataInvest = await HomeApi.getInvest()
                .then(res => {
                    this.isLoadInvest = true;
                    return res.data;
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        } else {
            this.isLoadInvest = true;
        }
    }
    // private async getGr() {
    //     if (this.code !== '11' && this.code !== '19') {
    //         this.dataForm = await HomeApi.getGr()
    //             .then(res => {
    //                 this.isLoadGr = true;
    //                 return res.data;
    //             })
    //             .catch(error => {
    //                 // eslint-disable-next-line no-console
    //                 console.log(error);
    //             });
    //     } else {
    //         this.isLoadGr = true;
    //     }
    // }

    private async getKat() {
        if (this.code !== '23' && this.code !== '11' && this.code !== '19') {
            this.dataSummary = await HomeApi.getKat()
                .then(res => {
                    this.isLoadKat = true;
                    return res.data;
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        } else {
            this.isLoadKat = true;
        }
    }

    private async getIncome() {
        this.dataIncome = await HomeApi.getIncome()
            .then(res => {
                this.isLoadIncome = true;
                return res.data;
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    private async getOutcome() {
        this.dataOutcome = await HomeApi.getOutcome()
            .then(res => {
                this.isLoadOutcome = true;
                return res.data;
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    private async getBip() {
        this.dataBip = await HomeApi.getBip()
            .then(res => {
                this.isLoadBip = true;
                return res.data;
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    private async getBip2() {
        this.dataBip2 = await HomeApi.getBip2()
            .then(res => {
                this.isLoadBip2 = true;
                return res.data;
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    private async getBip3() {
        this.dataBip3 = await HomeApi.getBip3()
            .then(res => {
                this.isLoadBip3 = true;
                return res.data;
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    private getBp() {
        if (this.code !== '23') {
            HomeApi.getBpFast()
                .then(res => {
                    this.isLoadBp = true;
                    for (const [key, value] of Object.entries(res.data)) {
                        this.$set(this.dataBp, key, value);
                    }
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        } else {
            this.isLoadBp = true;
        }
        if (this.code !== '23') {
            HomeApi.getBp()
                .then(res => {
                    this.isLoadBp = true;
                    for (const [key, value] of Object.entries(res.data)) {
                        this.$set(this.dataBp, key, value);
                    }
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        } else {
            this.isLoadBp = true;
        }
    }

    private mounted() {
        bridge.loadInitialPub();
        this.demoVisible = !(window as any).app.plainMenu;
        this.getBp();
        Promise.all([
            this.getCi(),
            this.getSnp(),
            this.getSnp2(),
            this.getSnp3(),
            this.getSnp4(),
            this.getInvest(),
            // this.getGr(),
            this.getKat(),
            this.getIncome(),
            this.getOutcome(),
            this.getBip(),
            this.getBip2(),
            this.getBip3()])
            .then(() => {
                this.isFetching = true;
            })
            .catch(error => {
                // eslint-disable-next-line no-alert
                alert(error);
            });
    }
}