










import {Component, Vue} from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import store from "@/services/store";
import {IVariant} from "@/modules/budget/budgetVariantService";


@Component({
    components: {
        'loading': VueElementLoading,
    }
})
export default class PurchasePlan extends Vue {
    private region = '00';

    private visible = true;
    private fields = [
        {
            key: 'date',
            label: 'Дата',
        }

    ]

    private count_data = []

    setVisible(vis: boolean) {
        this.visible = vis
    }

    async load_data() {
        const response = await fetch('api-py/get-realm-users-login');
        const result = await response.json();
        this.count_data = result.data;
        for (const col of result.columns) {
            const field = {
                key: col,
                label: col,
                tdClass: "text-right"
            }
            this.fields.push(field)
        }
    }

    mounted() {
        this.region = store.state._instanceCode;
        this.load_data()
    }

}
